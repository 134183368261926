import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {Asset, hierarchicalMenu, Link} from "../../plugins/gatsby-plugin-atollon";
import {colors} from "./primitives/colors";
import Container from "./primitives/grid/container";
import Logo from "../assets/images/logo.svg";
import Typography from "./primitives/typography";
import {StyledButtonAsLink} from "./primitives/styled-button";
import VisuallyHidden from "./primitives/visually-hidden";
import {Drawer, DrawerContent} from "./primitives/drawer";
import {breakpoints} from "./primitives/tokens";
import DirectionTransition from "./animations/transitions/direction-transition";
import {
    hierarchicalMenuSelectChild
} from "../../plugins/gatsby-plugin-atollon/real-src/components/utilities/hierarchical-menu";
import Row from "./primitives/grid/row";
import Col from "./primitives/grid/col";
import RowSet from "./primitives/grid/row-set";
import Hamburger from "./hamburger";
import {useStore} from "../contexts/store-context";


const MobileHeader = () => {
    const {
        allDatoCmsHeaderMenuItem: {
            nodes: headerItems
        },
        datoCmsSiteOptionPage: {
            helpHeaderMenuLink
        },
        datoCmsMobileMenuFooter
    } = useStaticQuery(graphql`
        query {
            allDatoCmsHeaderMenuItem {
                nodes {
                    id
                    title
                    link {
                        ...LinkWithoutTitleProps
                    }
                    description
                    position
                    treeParent {
                        id
                    }
                    inlineLink {
                        ...LinkWithTitleProps
                    }
                    icon {
                        ...InternalAssetProps
                    }
                    image {
                        ...InternalAssetProps
                    }
                }
            }
            datoCmsSiteOptionPage {
                helpHeaderMenuLink {
                    ...LinkWithTitleProps
                }
            }
            datoCmsMobileMenuFooter {
                leftColumnTitle
                leftColumnLinks {
                    ...LinkWithTitleProps
                }
                rightColumnTitle
                rightColumnLinks {
                    ...LinkWithTitleProps
                }
            }
        }
    `)
    const menuItems = hierarchicalMenu(headerItems);
    const drawerRef = React.useRef(null);

    const [open, setOpen] = React.useState(false);

    const [currentMenu, setCurrentMenu] = React.useState(menuItems)
    const [menuDirection, setMenuDirection] = React.useState('forward')

    const {
        cart
    } = useStore();

    React.useEffect(() => {
        if (!open) {
            setCurrentMenu(menuItems)
            setMenuDirection('forward')
        }
    }, [open])

    return (
        <>
            <div ref={drawerRef} />
            {drawerRef?.current && (
                <Drawer open={open}>
                    <DrawerContent animation="fade" placement="top" size="full" container={drawerRef?.current || undefined}>
                        <div
                            css={{
                                flexGrow: '1',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                overflowY: 'scroll',
                                WebkitOverflowScrolling: 'touch',
                                overflowX: 'hidden',
                                height: '100%',
                            }}
                        >
                            <DirectionTransition
                                shouldChange={JSON.stringify(currentMenu)}
                                direction={menuDirection}
                                css={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            ><Container css={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <div css={{
                                    paddingTop: '92px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: '100%',
                                }}>
                                    {currentMenu.id !== menuItems.id && (
                                        <button type="button" css={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            width: '100%',
                                            marginBottom: '24px',
                                            gap: '8px'
                                        }}
                                                onClick={() => {
                                                    setMenuDirection('backward');
                                                    if (currentMenu?.treeParent) {
                                                        const menuItemSet = hierarchicalMenuSelectChild(
                                                            headerItems,
                                                            currentMenu.treeParent.id,
                                                        );
                                                        if (menuItemSet) {
                                                            setCurrentMenu(menuItemSet);
                                                            return;
                                                        }
                                                    }
                                                    setCurrentMenu(menuItems);
                                                }}
                                        >
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1844_69461)">
                                                    <path d="M10.668 3.82812C10.668 3.82812 7.18593 8.64074 3.83203 9.98929H16.2595M10.668 16.1486C10.668 16.1486 7.18593 11.3357 3.83203 9.98743H16.2595" stroke="#1A1A1A" strokeWidth="0.714286" strokeLinejoin="bevel"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1844_69461">
                                                        <rect width="20" height="20" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <Typography
                                                fontSize="s1"
                                                color="neutral10"
                                            >
                                                Back
                                            </Typography>
                                        </button>
                                    )}
                                    {currentMenu.id !== menuItems.id && (
                                        <div css={{
                                            //marginBottom: '28px',
                                        }}>
                                            <div css={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                gap: '12px'
                                            }}>
                                                {currentMenu?.icon && (
                                                    <Asset css={{
                                                        width: '28px',
                                                        height: '28px',
                                                    }} asset={currentMenu.icon} />
                                                )}
                                                <Typography fontSize="h3">
                                                    {currentMenu.title}
                                                </Typography>
                                            </div>
                                            {currentMenu?.description && (
                                                <Typography css={{
                                                    marginTop: '16px'
                                                }} fontSize="p1">
                                                    {currentMenu.description}
                                                </Typography>
                                            )}
                                            {currentMenu?.inlineLink?.length > 0 && (
                                                <div css={{
                                                    marginTop: '28px'
                                                }}>
                                                    <StyledButtonAsLink onClick={() => {
                                                        setOpen(false)
                                                    }} className="outlined-orange" {...currentMenu.inlineLink} />
                                                </div>
                                            )}
                                            <hr css={{
                                                paddingTop: '48px',
                                                borderBottom: `1px solid ${colors.secondaryBeige4}`,
                                                margin: '0',
                                                marginLeft: '-16px',
                                                borderTop: 'none',
                                                width: 'calc(100% + 32px)',
                                                marginBottom: '28px'
                                            }} />
                                        </div>
                                    )}
                                    <div css={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '12px'
                                    }}>
                                        {currentMenu.childMenu.map((menuItem) => {

                                            if (menuItem.childMenu?.length > 0) {
                                                return (
                                                    <button
                                                        type="button"
                                                        css={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            width: '100%',
                                                            marginBottom:  menuItem?.icon ? '8px' : undefined,
                                                        }}
                                                        onClick={() => {
                                                            setCurrentMenu(menuItem);
                                                            setMenuDirection('forward');
                                                        }}
                                                    >
                                                        <div css={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            width: '100%',
                                                            gap: '12px'
                                                        }}>
                                                            {menuItem?.icon && (
                                                                <Asset css={{
                                                                    width: '28px',
                                                                    height: '28px',
                                                                }} asset={menuItem.icon} />
                                                            )}
                                                            <Typography
                                                                fontSize="s1"
                                                                color="neutral10"
                                                            >
                                                                {menuItem.title}
                                                            </Typography>
                                                        </div>
                                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.0645 22.6408C13.0645 22.6408 17.9395 15.9031 22.6351 14.0151H5.23633M13.0645 5.39209C13.0645 5.39209 17.9395 12.1301 22.6351 14.0177H5.23633" stroke="#1A1A1A" strokeLinejoin="bevel"/>
                                                        </svg>
                                                    </button>
                                                )
                                            }

                                            return (
                                                <Link
                                                    onClick={() => {
                                                        setOpen(false)
                                                    }}
                                                    {...menuItem.link}
                                                    css={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-start',
                                                        width: '100%',
                                                        gap: '12px'
                                                    }}>
                                                        {menuItem?.icon && (
                                                            <Asset css={{
                                                                width: '28px',
                                                                height: '28px',
                                                            }} asset={menuItem.icon} />
                                                        )}
                                                        <Typography
                                                            fontSize="s1"
                                                            color="neutral10"
                                                        >
                                                            {menuItem.title}
                                                        </Typography>
                                                </Link>
                                            )

                                        })}
                                    </div>
                                </div>
                                {currentMenu.id === menuItems.id && (<div>
                                    <Row css={{
                                        marginTop: '48px',
                                        marginBottom: '24px',
                                    }}>
                                        <Col breakpoints={{
                                            dt: { span: 6 },
                                            mb: { span: 2 }
                                        }}>
                                            <Typography css={{
                                                marginBottom: '8px'
                                            }} fontSize="p1" color="neutral10">
                                                {datoCmsMobileMenuFooter?.leftColumnTitle}
                                            </Typography>
                                            <RowSet breakpoints={{
                                                dt: { between: 2 }
                                            }}>
                                                {datoCmsMobileMenuFooter?.leftColumnLinks?.map((link) => {
                                                    const props = { "0": link }
                                                    return (
                                                        <Typography as={Link} css={{
                                                            display: 'block'
                                                        }} fontSize="p1" fontWeight="light" color="shadesBlack" {...props}  />
                                                    )
                                                })}
                                            </RowSet>
                                        </Col>
                                        <Col breakpoints={{
                                            dt: { span: 6 },
                                            mb: { span: 2 }
                                        }}>
                                            <Typography css={{
                                                marginBottom: '8px'
                                            }} fontSize="p1" color="neutral10">
                                                {datoCmsMobileMenuFooter?.rightColumnTitle}
                                            </Typography>
                                            <RowSet breakpoints={{
                                                dt: { between: 2 }
                                            }}>
                                                {datoCmsMobileMenuFooter?.rightColumnLinks?.map((link) => {
                                                    const props = { "0": link }
                                                    return (
                                                        <Typography as={Link} css={{
                                                            display: 'block'
                                                        }} fontSize="p1" fontWeight="light" color="shadesBlack" {...props}  />
                                                    )
                                                })}
                                            </RowSet>
                                        </Col>
                                    </Row>
                                </div>)}
                            </Container>
                            </DirectionTransition>
                        </div>
                    </DrawerContent>
                </Drawer>
            )}
            <div css={{
                zIndex: 10000,
                position: 'relative',
                borderBottom: `1px solid ${colors.secondaryBeige4}`,
                '.white-logo.no-menu &': {
                    borderBottom: '1px solid transparent'
                },
                display: 'none',
                [breakpoints.headerBreakpoint]: {
                    display: 'block',
                }
            }}>
                <Container>
                    <div css={{
                        transition: '0.3s',
                        background: 'linear-gradient(180deg, white, white, transparent)',
                        position: 'absolute',
                        height: '100%',
                        left: '0',
                        top: '0',
                        width: '100%',
                        '.white-logo &[data-open="false"]': {
                            opacity: 0
                        }
                    }} data-open={open} />
                    <div css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        pointerEvents: 'auto',
                        position: 'relative',
                    }}>
                        <Link to="/" onClick={() => {
                            setOpen(false)
                        }} css={{
                            svg: {
                                transition: '0.3s',
                            },
                            color: colors.primaryOrange,
                            '.white-logo &[data-open="false"]': {
                                color: colors.shadesWhite
                            }
                        }} data-open={open}>
                            <Logo />
                        </Link>
                        <div css={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: '16px'
                        }}>
                            <StyledButtonAsLink css={{
                                height: '31px',
                                padding: '3.5px 15px',
                                '.no-menu &': {
                                    display: 'none'
                                },
                            }} className="outlined-orange dt-p2 medium" {...helpHeaderMenuLink} />
                            <div css={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                gap: '16px'
                            }}>
                                <Link to="/cart" css={{
                                    '.no-menu &': {
                                        display: 'none'
                                    },
                                    position: 'relative',
                                }}>
                                    <VisuallyHidden>Cart</VisuallyHidden>
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_2973_41119" fill="white">
                                            <path d="M0.709961 6.21436H19.25V19.3979H0.709961V6.21436Z"/>
                                        </mask>
                                        <path d="M0.709961 6.21436V5.31436H-0.190039V6.21436H0.709961ZM19.25 6.21436H20.15V5.31436H19.25V6.21436ZM19.25 19.3979V20.2979H20.15V19.3979H19.25ZM0.709961 19.3979H-0.190039V20.2979H0.709961V19.3979ZM0.709961 7.11436H19.25V5.31436H0.709961V7.11436ZM18.35 6.21436V19.3979H20.15V6.21436H18.35ZM19.25 18.4979H0.709961V20.2979H19.25V18.4979ZM1.60996 19.3979V6.21436H-0.190039V19.3979H1.60996Z" fill="#1A1A1A" mask="url(#path-1-inside-1_2973_41119)"/>
                                        <path d="M14.2853 6.21442C14.2853 3.84749 12.3665 1.92871 9.99958 1.92871C7.63265 1.92871 5.71387 3.84749 5.71387 6.21442" stroke="#1A1A1A" strokeWidth="0.9"/>
                                    </svg>
                                    {cart?.lines?.length > 0 && (
                                        <div css={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            pointerEvents: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            top: '3px',
                                            fontSize: '10px'
                                        }}>
                                            {cart?.lines?.length}
                                        </div>
                                    )}
                                </Link>
                                <div css={{
                                    padding: '20px 0',
                                    color: colors.shadesBlack,
                                    transition: 'color 0.2s',
                                    '.white-logo &': {
                                        color: colors.shadesWhite,
                                        '&[data-open="true"]': {
                                            color: colors.shadesBlack,
                                        }
                                    },
                                }}>
                                    <Hamburger
                                        open={open}
                                        setOpen={setOpen}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default MobileHeader
